<template>
  <div>
    <ComiteFormulario
      v-auth-acl="'gh-cal-com_gh-insert'"
      ref="comiteFormulario"
      :dialog="dialogPopup"
      @open="openPopup()"
      @close="closePopup()"
      :editar="editar"
    ></ComiteFormulario>
    <div class="mt-0 pa-0">
      <div class="w-100 d-flex align-center justify-space-between pt-2 pb-3">
        <div class="d-flex align-center">
          <v-btn fab text small color="grey darken-2" @click="prev" v-if="tipo != 'year'">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar-title v-if="tipo !== 'year'">{{ title }}</v-toolbar-title>
          <v-btn fab text small color="grey darken-2" @click="next" v-if="tipo != 'year'">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn outlined color="grey darken-2" v-on="on">
                <span>{{ typeToLabel[tipo] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="
                  tipo = 'day';
                  type = 'day';
                "
              >
                <v-list-item-title>Días</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  tipo = 'week';
                  type = 'week';
                "
              >
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  tipo = 'month';
                  type = 'month';
                "
              >
                <v-list-item-title>Mes</v-list-item-title>
              </v-list-item>
              <v-list-item @click="tipo = 'year'">
                <v-list-item-title>Año</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-auth-acl="'gh-cal-com_gh-insert'">
          <v-btn class="text-capitalize mt-0 element-0" color="primary" @click="openPopup()">
            Crear Comité
          </v-btn>
        </div>
      </div>
      <v-sheet height="600" v-if="tipo !== 'year'">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :event-color="getEventColor"
          :events="events"
          :now="today"
          :type="type"
          locale="es-es"
          :event-more-text="`mostrar más`"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar dense :color="selectedEvent.color" dark>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip v-auth-acl="'gh-cal-com_gh-edit'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="editarComite(selectedEvent, 'E')">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar Comite</span>
              </v-tooltip>
              <v-tooltip v-auth-acl="'gh-cal-com_gh-duplicate'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="editarComite(selectedEvent, 'D')">
                    <v-icon>mdi-calendar-multiple</v-icon>
                  </v-btn>
                </template>
                <span>Duplicar Comite</span>
              </v-tooltip>
              <v-tooltip
                v-auth-acl="'gh-cal-com_gh-view'"
                v-if="
                  selectedEvent.idacta !== undefined && selectedEvent.idacta !== null && selectedEvent.idacta !== ''
                "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="verActa(selectedEvent)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Ver Acta</span>
              </v-tooltip>
              <v-tooltip v-auth-acl="'gh-cal-com_gh-generate'" v-else bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="actaEvento(selectedEvent)">
                    <v-icon>mdi-file-document</v-icon>
                  </v-btn>
                </template>
                <span>Generar Acta</span>
              </v-tooltip>
              <v-tooltip v-auth-acl="'gh-cal-com_gh-sendnotification'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="notificar(selectedEvent)">
                    <v-icon>mdi-email</v-icon>
                  </v-btn>
                </template>
                <span>Notificar</span>
              </v-tooltip>
              <v-btn icon @click="selectedOpen = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <div v-if="selectedEvent.invitados != undefined ? selectedEvent.invitados.length > 0 : false">
                <div class="d-flex ma-2">
                  <v-icon class=" mr-2">fas fa-users</v-icon>
                  <h3 class="font-weight-bold">INVITADOS/PARTICIPANTES</h3>
                </div>
                <ul v-for="(invitado, i) in selectedEvent.invitados" :key="i">
                  <li>
                    {{ invitado.nombres }}
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-sheet>
      <v-sheet height="700" v-if="tipo == 'year'">
        <Calendar
          id="calendar"
          language="es"
          :data-source="events"
          @mouse-on-day="mouseOnDay"
          @mouse-out-day="mouseOutDay"
          @click-day="viewDay"
          :display-week-number="true"
          :min-date="new Date(currentYear - 1, 12, 1)"
          :max-date="new Date(currentYear, 11, 31)"
        ></Calendar>
      </v-sheet>
    </div>
    <ActasView :object="objectViewActa" :dialog="dialogActasView" @closeActasView="closeActasView"></ActasView>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import ComiteService from '../services/ComiteService';
import moment from 'moment';
import tippy, { animateFill } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/themes/light.css';
import Calendar from 'v-year-calendar';
import 'v-year-calendar/locales/v-year-calendar.es';
import ActasView from '@/giplan/actas/matriz_actas/components/ActasView';
import { notificateComiteCalendar } from '@/services/notificationService';
import { decryptAES } from '@/components4x/utils/utils4x';
import Swal from 'sweetalert2';

export default {
  name: 'ComiteCalendario',
  components: {
    Calendar,
    ActasView,
    ComiteFormulario: () => import('../components/ComiteFormulario')
  },
  data: () => ({
    editar: [
      {
        editar: false
      }
    ],
    dialogActasView: false,
    objectViewActa: {
      agendas: [],
      areaescribano: '',
      compromisos: [],
      escribano: '',
      invitados: [],
      idarea: '',
      idresponsable: '',
      idtrabajador: '',
      lider: '',
      nombresall: ''
    },
    showLoading: false,
    comiteService: null,
    currentYear: new Date().getFullYear(),
    tooltip: null,
    focus: '',
    type: 'month',
    tipo: 'month',
    today: null,
    typeToLabel: {
      month: 'Mes',
      week: 'Semana',
      day: 'Día',
      year: 'Año'
    },
    dialogPopup: false,
    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      '#F44336',
      '#E91E63',
      '#9C27B0',
      '#673AB7',
      '#F44336',
      '#2196F3',
      '#03A9F4',
      '#00BCD4',
      '#009688',
      '#4CAF50',
      '#8BC34A',
      '#CDDC39',
      '#FFEB3B',
      '#FFC107',
      '#FF9800',
      '#FF5722',
      '#795548',
      '#607D8B',
      '#9E9E9E'
    ]
  }),
  computed: {
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return '';
      }
      switch (this.type) {
        case 'month':
          return `${this.monthFormatter(start)} del ${start.year}`;
        case 'week':
          return ` ${start.day}/${start.month}/${start.year} al ${end.day}/${end.month}/${end.year}`;
        case 'day':
          return `${start.day} de ${this.monthFormatter(start)} del ${start.year}`;
      }
      return '';
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC',
        month: 'long'
      });
    }
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    async notificar(event) {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: `¿Está seguro de notificar el comité?`,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        let fecha = moment(event.end);
        let fecha2 = moment();
        let dias = fecha.diff(fecha2, 'days');
        let hora = moment(event.start).format('HH:mm') + ' a ' + moment(event.end).format('HH:mm');
        const invitados = [];
        const participantes = [];
        let invitadosNoti = [];
        let participantemails = [];
        let participantesNoti = [];
        let mensaje = '';

        if (dias == 0) {
          mensaje = `Te queda menos de un día para la reunión del comité ${event.name} de ${hora}`;
        }
        if (dias < 0) {
          mensaje = `Ha pasado ${Math.abs(dias)} días desde que paso la reunión del comité ${event.name} de ${hora}`;
        }
        if (dias > 0) {
          mensaje = `Te quedan ${dias} días para la reunión del comité ${event.name} de ${hora}`;
        }
        event.invitados.forEach((el) => {
          invitados.push(el.idresponsable);
          participantes.push(el.email);
        });
        event.idlider.forEach((el) => {
          invitados.push(el.idlider);
          participantes.push(el.email);
        });
        event.idsecretario.forEach((el) => {
          invitados.push(el.idsecretario);
          participantes.push(el.email);
        });
        participantemails = participantes.filter((el, index, array) => index === array.indexOf(el));
        participantesNoti = [
          {
            email: participantemails,
            subject: 'NOTIFICACIÓN COMITÉ',
            content: mensaje
          }
        ];
        invitadosNoti = new Set(invitados);
        /* participantesNoti = participantes.filter(
          (element, index, array) => index === array.findIndex((el) => el.email === element.email)
        ); */
        for (const participant of invitadosNoti) {
          await notificateComiteCalendar(participant, mensaje);
        }
        const res = await this.comiteService.post().execResource('sendemail', {
          participantes: participantesNoti
        });
        if (res.status) {
          await this.alertDialog('success', res.data);
        } else {
          await this.alertDialog('error', res.data);
        }
        this.showLoading = false;
      }
    },
    editarComite(event, tipo) {
      this.editar = [];
      if (tipo == 'E') {
        this.editar.push({
          color: event.color,
          idacta: event.idacta,
          idlider: event.idlider,
          idresponsable: event.idresponsable,
          idsecretario: event.idsecretario,
          idcomite: event.idcomite,
          idcomiteitem: event.idcomiteitem,
          nombrelider: event.nombrelider,
          nombreresponsable: event.nombreresponsable,
          nombresecretario: event.nombresecretario,
          item: event.item,
          editar: true,
          ver: false,
          titulo: event.name,
          inicio: moment(event.start).format('YYYY-MM-DD'),
          fin: moment(event.end).format('YYYY-MM-DD'),
          horaInicio: moment(event.start).format('HH:mm'),
          horaFin: moment(event.end).format('HH:mm'),
          todoDia: event.tododia == 0 ? false : true,
          seleccion: event.repetir,
          temasatratar: event.temasatratar,
          invitados: event.invitados
        });
      } else {
        this.editar.push({
          color: event.color,
          idacta: event.idacta,
          idlider: event.idlider,
          idresponsable: event.idresponsable,
          idsecretario: event.idsecretario,
          idcomite: event.idcomite,
          idcomiteitem: event.idcomiteitem,
          nombrelider: event.nombrelider,
          nombreresponsable: event.nombreresponsable,
          nombresecretario: event.nombresecretario,
          item: event.item,
          editar: false,
          ver: false,
          titulo: event.name,
          inicio: moment(event.start).format('YYYY-MM-DD'),
          fin: moment(event.end).format('YYYY-MM-DD'),
          horaInicio: moment(event.start).format('HH:mm'),
          horaFin: moment(event.end).format('HH:mm'),
          todoDia: event.tododia == 0 ? false : true,
          seleccion: event.repetir,
          temasatratar: event.temasatratar,
          invitados: event.invitados
        });
      }
      this.openPopup();
    },
    /// ANUAL INICIO ////
    mouseOnDay: function(e) {
      if (e.events.length > 0) {
        var content = '';
        for (var i in e.events) {
          content +=
            '<div class="event-tooltip-content style="margin: 5px">' +
            '<div class="event-name" style="padding: 10px">' +
            '<i class="fas fa-circle" style="margin-right:12px;color:' +
            e.events[i].color +
            '"></i>' +
            e.events[i].name +
            '</div>' +
            '</div>';
        }
        if (this.tooltip != null) {
          this.tooltip.destroy();
          this.tooltip = null;
        }

        this.tooltip = tippy(e.element, {
          placement: 'right',
          content: '<h3>COMITES DEL DÍA</h3>' + content,
          allowHTML: true,
          animation: 'shift-away',
          animateFill: false,
          plugins: [animateFill],
          arrow: true,
          theme: 'light'
        });
        this.tooltip.show();
      }
    },
    mouseOutDay: function() {
      if (this.tooltip !== null) {
        this.tooltip.destroy();
        this.tooltip = null;
      }
    },
    /// ANUAL FIN ////
    openPopup() {
      this.dialogPopup = true;
    },
    closePopup() {
      this.dialogPopup = false;
      this.$refs.comiteFormulario.clearDialog();
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
      this.tipo = 'day';
    },
    setToday() {
      this.focus = this.today;
    },
    getEventColor(event) {
      return event.color;
    },
    prev() {
      var inicio = Date.parse(this.currentYear + '-1-1');
      var fecha = Date.parse(this.$refs.calendar.$children[0].start);
      if (inicio < fecha) {
        this.$refs.calendar.prev();
      }
    },
    next() {
      var fin = Date.parse(this.currentYear + '-12-31');
      var fecha = Date.parse(this.$refs.calendar.$children[0].end);
      if (fecha < fin) {
        this.$refs.calendar.next();
      }
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    actaEvento(event) {
      this.$router.push({ path: '/actas/actas-reunion', query: { i: event.idcomiteitem, r: 'C' } });
    },
    async verActa({ idacta }) {
      this.showLoading = true;
      this.objectViewActa = {
        agendas: [],
        areaescribano: '',
        compromisos: [],
        escribano: '',
        invitados: [],
        idarea: '',
        idresponsable: '',
        idtrabajador: '',
        lider: '',
        nombresall: ''
      };

      const res = await this.comiteService.get().execResource('viewacta', {
        idacta: idacta
      });
      this.showLoading = false;
      this.objectViewActa = res;
      this.dialogActasView = true;
    },
    closeActasView() {
      this.objectViewActa = {
        agendas: [],
        areaescribano: '',
        compromisos: [],
        escribano: '',
        invitados: [],
        idarea: '',
        idresponsable: '',
        idtrabajador: '',
        lider: '',
        nombresall: ''
      };
      this.dialogActasView = false;
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
    },
    async cargarComites() {
      this.events = [];
      this.showLoading = true;
      const res = await this.comiteService.get().execResource('comites', {
        idempresa: decryptAES(localStorage.getItem('emp'))
      });
      if (res.length > 0) {
        res.forEach((el) => {
          this.events.push({
            idacta: el.idacta,
            idlider: el.idlider,
            idresponsable: el.idresponsable,
            idsecretario: el.idsecretario,
            item: el.item,
            name: el.titulo,
            nombrelider: el.nombrelider,
            nombreresponsable: el.nombreresponsable,
            nombresecretario: el.nombresecretario,
            start: moment(el.start).format('YYYY-MM-DD HH:mm'),
            end: moment(el.end).format('YYYY-MM-DD HH:mm'),
            startDate: moment(moment(el.start).format('YYYY-MM-DD')),
            endDate: moment(moment(el.end).format('YYYY-MM-DD')),
            color: el.color,
            idcomite: el.idcomite,
            idcomiteitem: el.idcomiteitem,
            invitados: el.invitados,
            temasatratar: el.temasatratar,
            repetir: el.repetir,
            tododia: el.tododia,
            tipo: el.tipo
            /* cadaalerta: el.cadaalerta,
            alerta: el.alerta */
          });
        });
      } else {
        this.events = [];
      }
      this.showLoading = false;
    }
  },
  async created() {
    this.comiteService = this.$httpService(new ComiteService(), this);
    await this.cargarComites();
  }
};
</script>
<style lang="scss">
body {
  font-family: 'Roboto', sans-serif;
}
</style>
