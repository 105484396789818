class ComiteService {
  constructor() {}
  resources = () => ({
    responsables: {
      uri: `/v1/responsables`,
      method: ['get']
    },
    guardar: {
      uri: `/v1/comites/guardar`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    editar: {
      uri: `/v1/comites/editar`,
      method: ['put']
    },
    comites: {
      uri: `/v1/calendario/comites`,
      method: ['get']
    },
    comite: {
      uri: `/v1/calendario/comite`,
      method: ['get']
    },
    viewacta: {
      uri: `/v1/actas/detalle`,
      method: ['get']
    },
    sendemail: {
      uri: `/v1/notificate/comites`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    }
  });
}

export default ComiteService;
